import React from 'react';
import Navigation from "../components/Navigation";

const NotFound = () => {
    return (
        <>
        <Navigation/>
        <div>
            <h1>
               404
            </h1>
        </div>
        </>
    );
};

export default NotFound;<h1>
Erreur 404</h1>