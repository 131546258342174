import React from 'react'
import { NavLink } from 'react-router-dom'
import { DarkMode } from './DarkMode'

const Navigation = () => {
  return (
    <div className='navigation'>
      <NavLink exact to='/' activeClassName='nav-active'>
        Portfolio
      </NavLink>
      <DarkMode />
      {/* <NavLink exact to="/a-propos" activeClassName="nav-active">
                A propos
            </NavLink> */}
    </div>
  )
}

export default Navigation
